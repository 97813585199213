<template>
  <div class="origin-warp">
    <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading" height="300px">
      <div class="origin-item" v-for="(item,index) in rankingBloggers" :key="index">
        <div class="origin-item-top">
          <!-- left -->
          <div class="origin-item-left">
            <div :class="['origin-item-icon',{'icon1':index==0},{'icon2':index==1},{'icon3':index >=2}]">{{index+1}}</div>
            <router-link tag="div" :to="{path:'/bloggerDetails',query:{ id:item.blogger.id}}"  class="origin-item-avatar">
              <ImgDecypt :src="item.blogger.avatarUrl" :round="true" class="origin-item-avatarImg" />
              <img :src="avatarFrame" class="avatarFrame">
            </router-link>
            <div class="origin-item-title">
              {{item.blogger.nickName}}
            </div>
          </div>
          <!-- right -->
          <div class="origin-item-right">
            <router-link :to="{path:'/bloggerMore',query:{id:item.blogger.id,title:title}}" class="blogger_more">
              查看更多 <van-icon name="arrow" />
            </router-link>
          </div>
        </div>
        <div class="origin-item-content">
          <van-grid direction="horizontal" :column-num="2" :border="false">
            <van-grid-item v-for="(mediaInfo,index) in item.mediaInfos" :key="index" class="van-grid-box" @click="onJumpVideo(mediaInfo)">
              <div class="cover-box">
                <ImgDecypt :src="mediaInfo.videoCover" class="mediaInfo-cover" />
                <div class="video-info flex-between">
                  <div class="left">
                    <span class="left-play"></span>{{mediaInfo.watchTimes|watchCount}}
                  </div>
                  <div class="right">{{mediaInfo.playTime|videotime}}</div>
                </div>
              </div>
              <p>{{mediaInfo.content}}</p>
              <CoveLabel class="cust_label" :item="mediaInfo" />
            </van-grid-item>
          </van-grid>
        </div>
        <AdvSwiper
            :advList="tagAd"
            v-if="(index + 1) % 3 == 0 && tagAd.length > 0"
            class="l_adv"
          />
      </div>
    </Pullrefresh>
  </div>
</template>

<script>
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import { bloggerRanking } from "@/api/on_demand.js";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { jumpVideo } from "@/utils/index.js";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  components: {
    Pullrefresh,
    CoveLabel,
    AdvSwiper
  },
  props: {
    rankingType: {
      default: "hot",
    },
    sort: {
      default: "",
    },
    title: {
      default: "人气博主",
    },
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      req: {
        pageNum: 1,
        pageSize: 10,
        isVip: false,
      },
      rankingBloggers: [],
      avatarFrame: require("@/assets/png/avatarFrame2.png"),
      tagAd:[]
    };
  },
  created() {
    this.tagAd = getAdItem(AdType.columnAd);
    this.getData();
  },
  methods: {
    //下拉刷新
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.req.pageNum = 1;
      this.rankingBloggers = [];
      this.getData("refresh");
    },
    //上滑加载
    onLoad() {
      this.req.pageNum++;
      this.getData();
    },
    //获取数据
    async getData(type) {
      let req = this.req;
      if (this.rankingType) {
        req.rankingType = this.rankingType;
      }
      if (this.sort && this.rankingType == "new") {
        req.sort = this.sort;
      }
      if (type != "refresh") {
        this.loading = true;
      }
      let ret = await this.$Api(bloggerRanking, this.req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if (ret.code == 200 && ret.data && ret.data.rankingBloggers) {
        let rankingBloggers = ret.data.rankingBloggers;
        this.rankingBloggers = this.rankingBloggers.concat(rankingBloggers);

        if (rankingBloggers.length == 0 && this.req.pageNum == 1) {
          this.isNoData = true;
          return;
        }
        if (ret.data.rankingBloggers.length < this.req.pageSize) {
          this.finished = true;
        }
      }
    },
    onJumpVideo(item) {
      jumpVideo(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.origin-warp {
  padding: 0 10px;
  .origin-item {
    .origin-item-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      margin: 0 0 10px 0;
      .origin-item-left {
        display: flex;
        align-items: center;
        .icon1 {
          background: url("../../../assets/png/icn_one.png");
        }
        .icon2 {
          background: url("../../../assets/png/icn_two.png");
        }
        .icon3 {
          background: url("../../../assets/png/icn_three.png");
        }
        .origin-item-icon {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background-size: 100%;
          color: #fff;
          font-size: 12px;
        }
        .origin-item-avatar {
          position: relative;
          overflow: hidden;
          width: 40px;
          height: 40px;
          .avatarFrame {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
          }
          .origin-item-avatarImg {
            position: absolute;
            top: 4px;
            left: 3px;
            width: 34px;
            height: 34px;
            overflow: hidden;
          }
        }
        .origin-item-title {
          padding-left: 10px;
          font-size: 14px;
          color: #1d1b1c;
          font-weight: 400;
        }
      }
      .origin-item-right {
        font-size: 11px;
        color: #abaaab;
      }
    }
    .origin-item-content {
      width: 100%;
      position: relative;
      .mediaInfo-cover {
        height: 108px;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
        /deep/ img {
          border-radius: 6px 6px 0 0;
        }
      }
      p {
        font-size: 12px;
        padding: 5px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 170px;
        text-align: left;
      }
      /deep/ .van-grid-item__content {
        display: block;
        padding: 2.5px;
      }
    }
  }
}

//图标
.cust_label {
  position: absolute;
  top: 2px;
  right: 2.5px;
}

//播放次数 时间
.cover-box {
  position: relative;
}

.l_adv {
    height: 100px;
    overflow: hidden;
    padding-top: 10px;
    /deep/ .swiper-container,/deep/ .swiperSlide{
      height: 100%;
    }
  }
.video-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 10px;
  color: #ffffff;
  .left {
    display: flex;
    align-items: center;
    .left-play {
      display: inline-block;
      margin-right: 3px;
      width: 10px;
      height: 10px;
      background: url("../../../assets/png/play_icon.png") center center
        no-repeat;
      background-size: 100%;
    }
  }
}
</style>