<template>
  <div class="originalPage">
    <!-- <AdvSwiper :advList="ATP_YcTurnList" class="adv" /> -->
    <div class="advBox">
      <div
        class="advItem"
        v-for="(item, index) in gridAdvList"
        :key="index"
        @click="jumpAdd(item)"
      >
        <div class="advImgBox">
          <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
        </div>
        <div class="advTitle">{{ item.name }}</div>
      </div>
    </div>
    <div class="my-tabs-box">
      <div class="my-tabs">
        <div
          :class="['my-tab', { active: myTabActive == 0 }]"
          @click="clickTab(0)"
        >
          人气博主
        </div>
        <div
          :class="['my-tab', { active: myTabActive == 1 }]"
          @click="clickTab(1)"
        >
          收益大牛
        </div>
        <div
          :class="['my-tab', { active: myTabActive == 2 }]"
          @click="clickTab(2)"
        >
          最佳新人
        </div>
      </div>
      <div class="filter">
        <van-dropdown-menu v-if="myTabActive == 2">
          <van-dropdown-item
            v-model="filterValue"
            :options="filterOption"
            get-container="body"
            @change="changeFilter"
          />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="original-content">
      <div class="bottom-all">
        <Originaitem
          class="bottom-content"
          v-if="myTabActive == 0"
          rankingType="hot"
          :sort="filterValue"
          :key="keyValue"
        />
        <Originaitem
          class="bottom-content"
          v-if="myTabActive == 1"
          rankingType="income"
          :sort="filterValue"
          :key="keyValue"
        />
        <Originaitem
          class="bottom-content"
          v-if="myTabActive == 2"
          rankingType="new"
          :sort="filterValue"
          :key="keyValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import Originaitem from "@/views/on_demand/original/originaitem.vue";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  name: "original",
  components: {
    AdvSwiper,
    Originaitem,
  },
  data() {
    return {
      ATP_YcTurnList: [],
      gridAdvList: [],
      myTabActive: 0,
      myTabtransition: "slide-right",
      filterOption: [
        { text: "最多播放", value: "watch" },
        { text: "最多收藏", value: "lick" },
      ],
      tabs: [
        {
          title: "人气博主",
          rankingType: "hot",
        },
        {
          title: "收益大牛",
          rankingType: "income",
        },
        {
          title: "最佳新人",
          rankingType: "new",
        },
      ],
      filterValue: "watch",
      keyValue: "hot",
      tagAd: [],
    };
  },
  created() {
    this.tagAd = getAdItem(AdType.columnAd);
    this.ATP_YcTurnList = getAdItem(AdType.ATP_YcTurn);
    this.gridAdvList = getAdItem(AdType.onDemandAdv).slice(0, 10);
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    //筛选
    changeFilter() {
      this.keyValue = this.filterValue;
    },
    clickTab(active) {
      if (active > this.myTabActive) {
        this.myTabtransition = "slide-right";
      } else {
        this.myTabtransition = "slide-left";
      }

      this.myTabActive = active;
      this.keyValue = this.tabs[this.myTabActive].rankingType;
    },
    // 监听左滑事件
    swipeLeft() {
      this.myTabActive--;
      if (this.myTabActive < 0) {
        this.myTabActive = 0;
      }
      this.myTabtransition = "slide-left";
    },
    // 监听右滑事件
    swiperight() {
      this.myTabActive++;
      if (this.myTabActive > 2) {
        this.myTabActive = 2;
      }
      this.myTabtransition = "slide-right";
    },
  },
};
</script>

<style lang="scss" scoped>
.my-tabs-box {
  z-index: 101;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
}
.my-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 40px;
  background: #f4e9d9;
  .my-tab {
    margin-left: 20px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    position: relative;
    transition-duration: 0.3s;
    padding: 5px 0 0 0;
    color: #6b6b6c;
  }
}

.active {
  color: red !important ;
}
.filter {
  position: absolute;
  right: 10px;
  top: 0;
  width: 100px;
  height: 40px;
  z-index: 101;
  /deep/ .van-dropdown-menu__bar {
    background: transparent !important;
    height: 40px;
    box-shadow: unset;
  }
  /deep/ .van-dropdown-menu__title {
    font-size: 14px;
  }
}

.bottom-all {
  position: relative;
}
.bottom-content {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}
.originalPage {
  height: calc(100vh - 148px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
/deep/ .van-dropdown-menu__title::after {
  position: absolute;
  top: 50%;
  right: -0.107rem;
  margin-top: -0.133rem;
  border: 0.08rem solid;
  border-color: transparent transparent #323233 #323233;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0.8;
  content: "";
}
.adv /deep/ .swiperSlide {
  height: 156.25px !important;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
